/* Home.css */

/* General styling for the hero section */
.hero-section {
  display: flex;
  flex-direction: row; /* Default: Image and text side by side */
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  /* gap: 20px; */
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%; /* Add buffer around the image */
}

.hero-image img {
  max-width: 70%;
  height: auto;
  border-radius: 8px;
  top: 2%;
  /* margin: 10px; // Add buffer inside the image container */
}

.hero-content {
  flex: 1;
  text-align: center;
}

.hero-content h1 {
  max-width: 70%;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.hero-content p {
  max-width: 70%;
  font-size: 1.2rem;
  text-align: center;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column; /* Stack image on top of text */
    text-align: center;
  }

  .hero-image {
    margin-bottom: 20px;
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 7.5%;
  }

  .hero-content h1, .hero-content p {
    max-width: 100%;
  }
}

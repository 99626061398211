.about-container {
padding: 20px;
margin: 0 10% auto;
/* max-width: 800px; */
}

.about-container h1 {
text-align: center;
margin-bottom: 40px;
}

.about-section {
margin-bottom: 30px;
}

.about-section h2 {
margin-bottom: 10px;
color: #333;
}

.about-section p {
font-size: 1.1em;
line-height: 1.6;
color: #555;
}

iframe {
display: block;
margin: 20px auto;
border: none;
}

.about-image {
display: block;
margin: 20px auto;
width: 100%;
height: auto;
border: 1px solid #ccc;
border-radius: 8px;
}

.image-row {
display: flex;
justify-content: space-between; /* Space between the images */
gap: 10px; /* Optional: Adds space between images */
}

.image-container {
display: flex;
flex-direction: column; /* Stack image and caption vertically */
align-items: center; /* Center-align the items */
width: 100%; /* Adjust as needed */
}

.caption {
margin-top: 10px; /* Space between image and caption */
text-align: center;
font-size: 1em;
color: #555; /* Adjust color as needed */
}

.video {
width: 100%;
height: 100%;
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333; /* Adjust to your desired color */
  color: white;
  padding: 10px 20px;
  z-index: 1000; /* Ensure it stays on top of other content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better separation */
}
  
  .navbar-menu {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
  }
  
  .navbar-item {
    margin: 0;
  }
  
  .navbar-link {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  
  .navbar-link:hover {
    background-color: #555;
  }  
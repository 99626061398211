.experience-container {
    padding: 20px;
    max-width: 80%;
    margin: 0 auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .timeline {
    position: relative;
    padding: 10px 0;
    list-style: none;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #ddd;
  }
  
  .timeline-item {
    position: relative;
    display: flex;
    margin: 3% 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
  }
  
  .timeline-item:nth-child(odd) {
    flex-direction: row-reverse;
  }
  
  .timeline-content,
  .timeline-image {
    width: 45%;
  }
  
  .timeline-content {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2%;
    z-index: 1;
    height: 100%
  }
  
  .timeline-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3%;
    margin-right: 3%;
    height: 100%; /* Set the container height to match the content */
  }
  
  .timeline-image img {
    max-width: 100%;
    max-height: 100%; /* Constrain the image height to the container height */
    object-fit: cover; /* Cover the area without distorting the image */
  }
  
  .timeline-date-start,
  .timeline-date-end {
    position: absolute;
    font-size: 1em;
    color: #888;
    width: max-content;
  }
  
  .timeline-date-start {
    top: -5%;
  }
  
  .timeline-date-end {
    bottom: -5%;
  }
  
  .timeline-item:nth-child(odd) .timeline-date-start {
    right: calc(50% + 2%);
  }
  
  .timeline-item:nth-child(even) .timeline-date-start {
    left: calc(50% + 2%);
  }
  
  .timeline-item:nth-child(odd) .timeline-date-end {
    right: calc(50% + 2%);
  }
  
  .timeline-item:nth-child(even) .timeline-date-end {
    left: calc(50% + 2%);
  }
  